import type { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { ElevateApps } from '@amzn/elevate-graphql/types';
import { matchRoutes, type Params } from 'react-router-dom';

import { ElevateAppLabels } from '@/components/app';

type Page = {
  app: ElevateApps;
  contentType: AppLayoutProps.ContentType;
};

export type PageDefinition = {
  attributes?: Record<string, string>;
  key: ElevatePageRoute;
  normPath: string;
  tags: string[];
} & Page;

function getAttributesFromParams(params: Params): Record<string, string> | undefined {
  const attributes: Record<string, string> = {};
  for (const [key, value] of Object.entries(params)) {
    if (!value) continue;
    attributes[key] = value;
  }
  return Object.keys(attributes).length ? attributes : undefined;
}

function makeStaticPath(value: string): string {
  const segments = value
    .replace(':orgId', 'interview-management')
    .split('/')
    .filter((segment) => !!segment && !segment.startsWith(':'));
  return `/${segments.join('/')}`;
}

function page(app: ElevateApps = ElevateApps.NONE, contentType: AppLayoutProps.ContentType = 'default'): Page {
  return { app, contentType };
}

const ivmPage = (contentType?: AppLayoutProps.ContentType): Page => page(ElevateApps.INTERVIEW_MANAGEMENT, contentType);
const qbPage = (contentType?: AppLayoutProps.ContentType): Page => page(ElevateApps.QUESTION_BANK, contentType);
const phPage = (contentType?: AppLayoutProps.ContentType): Page => page(ElevateApps.PROMOHUB, contentType);

const ELEVATE_PAGES = {
  '/': page(),
  '/access-denied': page(),
  '/interview-management': ivmPage(),
  '/not-found': page(),
  '/organizations': ivmPage('table'),
  '/organizations/:orgId/settings': ivmPage(),
  '/organizations/:orgId/settings/:tab?': ivmPage(),
  '/:orgId/bar-raisers': ivmPage('cards'),
  '/:orgId/calibration-shepherds': ivmPage('table'),
  '/:orgId/calibration-shepherd/interviewers': ivmPage('table'),
  '/:orgId/calibration-shepherd/interviewers/:impersonateProfileId': ivmPage(),
  '/:orgId/calibration-shepherd/interviewers/:impersonateProfileId/:tab?': ivmPage(),
  '/:orgId/interviewers': ivmPage('table'),
  '/:orgId/loops/bulk-create': ivmPage('form'),
  '/:orgId/loops/create': ivmPage('form'),
  '/:orgId/loops/:eventId/edit': ivmPage('form'),
  '/:orgId/loops/:eventId': ivmPage(),
  '/:orgId/loops/find/lead': ivmPage('table'),
  '/:orgId/loops/find/shadow': ivmPage('table'),
  '/:orgId/loops': ivmPage('table'),
  '/:orgId/phone-screens/available-interviewers': ivmPage('table'),
  '/:orgId/phone-screens/create': ivmPage('form'),
  '/:orgId/phone-screens/create/fromAvailability/:availabilityID': ivmPage('form'),
  '/:orgId/phone-screens/create/fromAvailability/:availabilityID/:availabilityDate': ivmPage('form'),
  '/:orgId/phone-screens/:eventId/edit': ivmPage('form'),
  '/:orgId/phone-screens/:eventId': ivmPage(),
  '/:orgId/phone-screens/find': ivmPage('table'),
  '/:orgId/phone-screens/find/shadow': ivmPage('table'),
  '/:orgId/phone-screens': ivmPage('table'),
  '/:orgId/team/calibration-status': ivmPage('table'),
  '/profile': page(),
  '/profile/:tab?': page(),
  '/question-bank': qbPage(),
  '/question-bank/teams': qbPage(),
  '/question-bank/teams/:teamID': qbPage(),
  '/question-bank/teams/*': qbPage(),
  '/question-bank/*': qbPage(),
  '/promohub': phPage(),
  '/promohub/panel-members': phPage('table'),
  '/promohub/reviewers': phPage('table'),
} as const satisfies Record<string, Page>;

export type ElevatePageRoute = keyof typeof ELEVATE_PAGES;

// Reconstitute the page object into a list of agnostic routes that react-router understands
const pageRoutes = Object.keys(ELEVATE_PAGES).map((path) => ({ path }));

function isPageRoute(value: unknown): value is ElevatePageRoute {
  return typeof value === 'string' && value in ELEVATE_PAGES;
}

export const ElevateAppRoutes = {
  [ElevateApps.NONE]: '/',
  [ElevateApps.INTERVIEW_MANAGEMENT]: '/interview-management',
  [ElevateApps.QUESTION_BANK]: '/question-bank',
  [ElevateApps.PROMOHUB]: '/promohub',
} as const satisfies Record<ElevateApps, ElevatePageRoute>;

export function getPage(fullPath: string): PageDefinition {
  const routeMatch = matchRoutes(pageRoutes, fullPath);

  const key = routeMatch?.[0].route.path;
  const pageKey: ElevatePageRoute = isPageRoute(key) ? key : '/';
  const page = ELEVATE_PAGES[pageKey];

  return {
    ...page,
    attributes: getAttributesFromParams(routeMatch?.at(0)?.params ?? {}),
    key: pageKey,
    normPath: makeStaticPath(pageKey),
    tags: page.app === ElevateApps.NONE ? [] : [ElevateAppLabels[page.app]],
  };
}
